import React from 'react';
import { NavLink } from 'react-router-dom';
import './Button.less';

const Button = ({ extraClass, textContent, linkTo }) => {
    return (
        <NavLink to={linkTo}>
            <button className={`btn ${extraClass}`}>{textContent}</button>
        </NavLink>
    );
};

export default Button;