import React from 'react';
import imgLogo from '../../../assets/logo.png';
import Links from '../../Links/Links';
import Button from '../../common/Button/Button';
import { NavLink } from 'react-router-dom';

import './MenuLayout.less';

const MenuLayout = ({ children }) => {
    window.addEventListener('scroll', (e) => {
        if (window.scrollY > 12) {
            document.querySelector('.menu').classList.add('menu--white');
        } else {
            document.querySelector('.menu').classList.remove('menu--white');
        }
    })
    return (
        <div className="menu" >
            <div className='container menu__container'>
                <NavLink to="/"><img src={imgLogo} className="logo"></img></NavLink>
                <Links />
                <Button linkTo='/wycena' textContent='Wycena' />
                <div className="burger" onClick={ () => document.body.classList.toggle('opened') }>
                    <div />
                    <div />
                    <div />
                </div>
            </div>
        </div>
    );
};

export default MenuLayout;


