import React  from 'react';
import { NavLink } from 'react-router-dom';

const Links = () => {
    const closeMenu = (changeUrl, site) => {
        document.body.classList.remove('opened');
        if(changeUrl === true) {
            window.location.href = site;
        }
    };
    
    return (
        <div className="links">
            
            <a className="menu__dropdown">
                <span>Oferta</span>

                <ul>
                    <li onClick={() => closeMenu(true, '/strony-internetowe')}>Strony WWW</li>
                    <li onClick={() => closeMenu(true, '/sklepy-internetowe')}>Sklepy internetowe</li>
                    <li onClick={() => closeMenu(true, '/identyfikacja-firmy')}>Identyfikacja firmy</li>
                    <li onClick={() => closeMenu(true, '/social-media')}>Social media</li>
                    <li onClick={() => closeMenu(true, 'https://stronawabonamencie.pl/')}>Strona w abonamencie</li>
                </ul>
            </a>
            <NavLink onClick={closeMenu} exact to="/cennik">
                Cennik
            </NavLink>
            <NavLink onClick={closeMenu} exact to="/o-nas">
                O nas
            </NavLink>
            <NavLink onClick={closeMenu} exact to="/realizacje">
                Realizacje
            </NavLink>
            <NavLink onClick={closeMenu} exact to="/kontakt">
                Kontakt
            </NavLink>
        </div>
    );
};

export default Links;