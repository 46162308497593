import React, { useEffect, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import Loader from 'react-loader-spinner';

const HomePage = React.lazy(() => import('../pages/HomePage'));
// const OfferPage = React.lazy(() => import('../pages/OfferPage'));
const ContactPage = React.lazy(() => import('../pages/ContactPage'));
const WycenaPakietPage = React.lazy(() => import('../pages/WycenaPakiet'));
const CennikPage = React.lazy(() => import('../pages/Cennik/Cennik'));
const EMarketingPage = React.lazy(() => import('../pages/EMarketingPage'));
const IdentyfikacjaFirmyPage = React.lazy(() => import('../pages/IndentyfikacjaFirmy/IdentyfikacjaFirmy'));
const SocialMediaPage = React.lazy(() => import('../pages/SocialMedia/SocialMedia'));
const PozycjonowaniePage = React.lazy(() => import('../pages/PozycjowaniePage'));
const NotFoundPage = React.lazy(() => import('../pages/NotFoundPage'));
const WycenaPage = React.lazy(() => import('../pages/WycenaPage'));
const OnasPage = React.lazy(() => import('../pages/O nas/Onas'));
const RealizacjePage = React.lazy(() => import('../pages/Realizacje/RealizacjePage'));
const StronyInternetowe = React.lazy(() => import('../pages/Strony-internetowe/Strony-internetowe'));
const SklepyInternetowe = React.lazy(() => import('../pages/Sklepy-internetowe/Sklepy-internetowe'));

/// POJEDYNCZE REALIZACJE

// import Doboxa from '../pages/Realizacja/Doboxa/Doboxa';
const Zgorzelec = React.lazy(() => import('../pages/Realizacja/Zgorzelec/Zgorzelec'));
const PowerKlima = React.lazy(() => import('../pages/Realizacja/PowerKlima/PowerKlima'));
// const Offer = React.lazy(() => import('../pages/OfferPage'));
const Doboxa = React.lazy(() => import('../pages/Realizacja/Doboxa/Doboxa'));
const DoboxaSklep = React.lazy(() => import('../pages/Realizacja/DoboxaSklep/Doboxa'));
const LookCook = React.lazy(() => import('../pages/Realizacja/LookCook/LookCook'));
const Tobi = React.lazy(() => import('../pages/Realizacja/Tobi/Tobi'));
const BowMax = React.lazy(() => import('../pages/Realizacja/BowMax/BowMax'));
const Robix = React.lazy(() => import('../pages/Realizacja/Robix/Robix'));
const Arlity = React.lazy(() => import('../pages/Realizacja/Arlity/Arlity'));
const Midparts = React.lazy(() => import('../pages/Realizacja/Midparts/Midparts'));
const DrHerbich = React.lazy(() => import('../pages/Realizacja/DrHerbich/DrHerbich'));
const PolitykaPrywatnosci= React.lazy(() => import('../pages/PolitykaPrywatnosci.js'));


const defaultTitle = 'Intermedia Agencja Reklamowa Zielona Góra';
const defaultDescription = 'Intermedia Agencja Reklamowa Zielona Góra, zajmuje się profesjonalnym tworzeniem nowoczesnych stron internetowych oraz e-commerce.';
const defaultKeywords = 'Agencja reklamowa Zielona Góra, Agencja Reklamowa, Agencja Interaktywna, Agencja Intermedia, Tworzenie stron internetowych, Tworzenie sklepów online, Strony www Zielona Góra, Agencja Zielona Góra, WordPress Zielona Góra, Sklepy Internetowe Zielona Góra, Pozycjonowanie Zielona Góra';

const Page = (props) => {
    useEffect(() => {
      document.title = props.title || defaultTitle;
      document.querySelector('meta[name="description"]').setAttribute("content", props.desc || defaultDescription );
      document.querySelector('meta[name="keywords"]').setAttribute("content", props.keys || defaultKeywords );
    });
    return props.children;
};


const Routes = () => {
    return (
      <Suspense fallback={
        <div className="spin">
          {/* <Loader
            type="Puff"
            color="#2A8CC3"
            height={200}
            width={200}
          /> */}
        </div>
      } >
        <Switch>
            
            <Route
              path="/"
              exact
              render={(props) => (
                <Page title="Intermedia Agencja Reklamowa Zielona Góra">
                  <HomePage {...props} />
                </Page>
              )}
            />

            {/* <Route
              path="/oferta"
              exact
              render={(props) => (
                <Page title="Oferta - Intermedia Agencja Reklamowa Zielona Góra">
                  <OfferPage {...props} />
                </Page>
              )}
            /> */}

            <Route
              path="/kontakt"
              exact
              render={(props) => (
                <Page title="Kontakt - Intermedia Agencja Reklamowa Zielona Góra">
                  <ContactPage {...props} />
                </Page>
              )}
            />

            <Route
              path="/wycena-pakiet/:id"
              exact
              render={(props) => (
                <Page title="Wycena pakietu - Intermedia Agencja Reklamowa Zielona Góra">
                  <WycenaPakietPage {...props} />
                </Page>
              )}
            />

            <Route
              path="/cennik"
              exact
              render={(props) => (
                <Page title="Cennik - Intermedia Agencja Reklamowa Zielona Góra">
                  <CennikPage {...props} />
                </Page>
              )}
            />

             <Route
              path="/pozycjonowanie"
              exact
              render={(props) => (
                <Page title="Pozycjonowanie, SEO - Intermedia Agencja Reklamowa Zielona Góra">
                  <PozycjonowaniePage {...props} />
                </Page>
              )}
            />

            <Route
              path="/social-media"
              exact
              render={(props) => (
                <Page title="Social Media - Intermedia Agencja Reklamowa Zielona Góra">
                  <SocialMediaPage {...props} />
                </Page>
              )}
            />

            <Route
              path="/identyfikacja-firmy"
              exact
              render={(props) => (
                <Page title="Identyfikacja firmy - Intermedia Agencja Reklamowa Zielona Góra">
                  <IdentyfikacjaFirmyPage {...props} />
                </Page>
              )}
            />

            <Route
              path="/e-marketing"
              exact
              render={(props) => (
                <Page title="Social Media - Intermedia Agencja Reklamowa Zielona Góra">
                  <EMarketingPage {...props} />
                </Page>
              )}
            />

            <Route
              path="/wycena"
              exact
              render={(props) => (
                <Page title="Wycena - Intermedia Agencja Reklamowa Zielona Góra">
                  <WycenaPage {...props} />
                </Page>
              )}
            />

            <Route
              path="/o-nas"
              exact
              render={(props) => (
                <Page title="O nas - Intermedia Agencja Reklamowa Zielona Góra">
                  <OnasPage {...props} />
                </Page>
              )}
            />

            <Route
              path="/realizacje"
              exact
              render={(props) => (
                <Page title="Realizacje - Intermedia Agencja Reklamowa Zielona Góra">
                  <RealizacjePage {...props} />
                </Page>
              )}
            />
            
            <Route
              path="/realizacje/doboxa"
              exact
              render={(props) => (
                <Page title="Realizacja - Intermedia Agencja Reklamowa Zielona Góra">
                  <Doboxa {...props} />
                </Page>
              )}
            />

            <Route
              path="/realizacje/doboxa-sklep"
              exact
              render={(props) => (
                <Page title="Realizacja - Intermedia Agencja Reklamowa Zielona Góra">
                  <DoboxaSklep {...props} />
                </Page>
              )}
            />

            <Route
              path="/realizacje/tobi"
              exact
              render={(props) => (
                <Page title="Realizacja - Intermedia Agencja Reklamowa Zielona Góra">
                  <Tobi {...props} />
                </Page>
              )}
            />

          <Route
              path="/realizacje/look-cook"
              exact
              render={(props) => (
                <Page title="Realizacja - Intermedia Agencja Reklamowa Zielona Góra">
                  <LookCook {...props} />
                </Page>
              )}
            />

            <Route
              path="/realizacje/czorny-diament"
              exact
              render={(props) => (
                <Page title="Realizacja - Intermedia Agencja Reklamowa Zielona Góra">
                  <Robix {...props} />
                </Page>
              )}
            />

            <Route
              path="/realizacje/drherbich"
              exact
              render={(props) => (
                <Page title="Realizacja - Intermedia Agencja Reklamowa Zielona Góra">
                  <DrHerbich {...props} />
                </Page>
              )}
            />

            <Route
              path="/realizacje/bow-max"
              exact
              render={(props) => (
                <Page title="Realizacja - Intermedia Agencja Reklamowa Zielona Góra">
                  <BowMax {...props} />
                </Page>
              )}
            />

            <Route
              path="/realizacje/zgorzelec"
              exact
              render={(props) => (
                <Page title="Realizacja - Intermedia Agencja Reklamowa Zielona Góra">
                  <Zgorzelec {...props} />
                </Page>
              )}
            />

             <Route
              path="/realizacje/arlity"
              exact
              render={(props) => (
                <Page title="Realizacja - Intermedia Agencja Reklamowa Zielona Góra">
                  <Arlity {...props} />
                </Page>
              )}
            />
           
           <Route
              path="/realizacje/powerklima"
              exact
              render={(props) => (
                <Page title="Realizacja - Intermedia Agencja Reklamowa Zielona Góra">
                  <PowerKlima {...props} />
                </Page>
              )}
            />

            <Route
              path="/realizacje/midparts"
              exact
              render={(props) => (
                <Page title="Realizacja - Intermedia Agencja Reklamowa Zielona Góra">
                  <Midparts {...props} />
                </Page>
              )}
            />
           
           <Route
              path="/strony-internetowe"
              exact
              render={(props) => (
                <Page title="Strony Internetowe - Intermedia Agencja Reklamowa Zielona Góra">
                  <StronyInternetowe {...props} />
                </Page>
              )}
            />

            <Route
              path="/polityka-prywatnosci"
              exact
              render={(props) => (
                <Page title="Polityka prywatnosci - Intermedia Agencja Reklamowa Zielona Góra">
                  <PolitykaPrywatnosci {...props} />
                </Page>
              )}
            />
            
           
           <Route
              path="/sklepy-internetowe"
              exact
              render={(props) => (
                <Page title="Sklepy Internetowe - Intermedia Agencja Reklamowa Zielona Góra">
                  <SklepyInternetowe {...props} />
                </Page>
              )}
            />

            <Route
             
              render={(props) => (
                <Page title="Sklepy Internetowe - Intermedia Agencja Reklamowa Zielona Góra - Nie znaleziono 404">
                  <NotFoundPage {...props} />
                </Page>
              )}
            />
            
            
        </Switch>
      </Suspense>
    );
}

export default Routes;




