import React, { Component } from 'react';
import Routes from './routing/Routes';
import MainLayout from './components/MainLayout/MainLayout';
import { ParallaxProvider } from 'react-scroll-parallax';


import { css as cssLoader } from 'store-css';

// import fonts stylesheet
cssLoader({
  url: 'https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap',
  crossOrigin: 'anonymous'
})



class App extends Component{
   render(){
      return(
         <ParallaxProvider>
            <MainLayout>
               <Routes />
            </MainLayout>
         </ParallaxProvider>
      );
   }
}

export default App;