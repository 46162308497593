import React from 'react';
import logo from '../../assets/logo.png';
import { NavLink } from 'react-router-dom';
import { Parallax } from 'react-scroll-parallax';

import rombYellow from '../../assets/backgrounds/romb-yellow.png';
import rombBlue from '../../assets/backgrounds/romb-blue.png';

import './Footer.less';

const Footer = () => {
    return (
        <footer className="footer">
            <Parallax className="romb romb-blue" y={[-180, 0]}  x={[-300, 0]} tagOuter="figure">
                    <img src={rombBlue} />
            </Parallax>
            <Parallax className="romb romb-yellow" y={[-180, 0]}  x={[-300, 0]} tagOuter="figure">
                    <img src={rombYellow} />
            </Parallax>
            <div className="footer__wrapper container">
                <div className="footer__column">
                    <img className="footer__logo" src={logo} alt="company logo" />
                    <a href="tel:0048607893457">+48 607 893 457</a>
                    <a rel="nofollow" href="mailto:biuro@e-intermedia.pl">biuro@e-intermedia.pl</a>
                    <div className="footer__item">S. Moniuszki 16/113</div>
                    <div className="footer__item">65-409 Zielona Góra</div>
                </div>
                <div className="footer__column">
                    <div className="footer__title">Firma</div>
                    <NavLink to="/o-nas">O nas</NavLink>
                    <NavLink to="/realizacje">Realizacje</NavLink>
                    <NavLink to="/kontakt">Kontakt</NavLink>
                    <NavLink to="/wycena">Wycena</NavLink>
                    <NavLink to="/polityka-prywatnosci">Polityka prywatnośći</NavLink>
                </div>
                <div className="footer__column">
                    <div className="footer__title">Oferta</div>
                    <NavLink to="/strony-internetowe">Strony internetowe</NavLink>
                    <NavLink to="/sklepy-internetowe">Sklepy on-line</NavLink>
                    <NavLink to="/identyfikacja-firmy">Identyfikacja wizualna</NavLink>
                    <NavLink to="/social-media">Social Media</NavLink>
                    <a href="https://stronawabonamencie.pl/">Strona w abonamencie</a>
                    {/* <NavLink to="/price">Pozycjonowanie</NavLink> */}
                </div>
                <div className="footer__column">
                    <div className="footer__title">Realizacje</div>
                    <NavLink to="/realizacje/doboxa">Doboxa.biz</NavLink>
                    <NavLink to="/realizacje/zgorzelec">Zgorzelec</NavLink>
                    <a href="http://racula.pl/">Racula.pl</a>
                    <NavLink to="/realizacje/powerklima">PowerKlima</NavLink>
                </div>
                <div className="footer__column footer__socials">
                    <a href="https://www.facebook.com/IntermediaAgencjaInteraktywna/">
                        <svg id="Bold" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg">
                            <path d="m15.997 3.985h2.191v-3.816c-.378-.052-1.678-.169-3.192-.169-3.159 
                        0-5.323 1.987-5.323 5.639v3.361h-3.486v4.266h3.486v10.734h4.274v-10.733h3.345l.531-4.266h-3.877v-2.939c.001-1.233.333-2.077 2.051-2.077z"/>
                        </svg>
                    </a>
                    <a href="https://www.linkedin.com/company/intermedia-zielona-gora/">
                        <svg id="Bold" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg">
                            <path d="m23.994 24v-.001h.006v-8.802c0-4.306-.927-7.623-5.961-7.623-2.42 0-4.044 1.328-4.707 2.587h-.07v-2.185h-4.773v16.023h4.97v-7.934c0-2.089.396-4.109 2.983-4.109 2.549 0 2.587 2.384 2.587 4.243v7.801z" />
                            <path d="m.396 7.977h4.976v16.023h-4.976z" />
                            <path d="m2.882 0c-1.591 0-2.882 1.291-2.882 2.882s1.291 2.909 2.882 2.909 2.882-1.318 2.882-2.909c-.001-1.591-1.292-2.882-2.882-2.882z" />
                        </svg>
                    </a>
                    <a className="insta" href="https://www.instagram.com/intermediazg/">
                        <svg height="512pt" viewBox="0 0 512 512" width="512pt" xmlns="http://www.w3.org/2000/svg">
                            <path d="m301 256c0 24.851562-20.148438 45-45 45s-45-20.148438-45-45 20.148438-45 45-45 45 20.148438 45 45zm0 0"/>
                            <path d="m332 120h-152c-33.085938 0-60 26.914062-60 60v152c0 33.085938 26.914062 60 60 60h152c33.085938 0 
                            60-26.914062 60-60v-152c0-33.085938-26.914062-60-60-60zm-76 211c-41.355469 0-75-33.644531-75-75s33.644531-75 75-75 75 
                            33.644531 75 75-33.644531 75-75 75zm86-146c-8.285156 0-15-6.714844-15-15s6.714844-15 15-15 15 6.714844 15 15-6.714844 15-15 15zm0 0"/>
                            <path d="m377 0h-242c-74.4375 0-135 60.5625-135 135v242c0 74.4375 60.5625 135 135 135h242c74.4375 0 135-60.5625 
                            135-135v-242c0-74.4375-60.5625-135-135-135zm45 332c0 49.625-40.375 90-90 90h-152c-49.625 0-90-40.375-90-90v-152c0-49.625 
                            40.375-90 90-90h152c49.625 0 90 40.375 90 90zm0 0"/>
                        </svg>
                    </a>
                </div>
            </div>

            <div className="footer__bottom">
                © Copyright 2020 by <b>Intermedia Agencja Interkatywna</b>
            </div>
        </footer>
    )
};

export default Footer;