import React from 'react';
import ReactDOM from 'react-dom';
import { Router  } from 'react-router-dom';
import App from './App.js';
import history from './utils/history';
import ScrollToTop from './components/ScrollToTop';



import './styles/styles.less';

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(
      <Router history={history}  basename={process.env.PUBLIC_URL}>
          <ScrollToTop />
          <App />
      </Router>, rootElement);
} else {
  ReactDOM.render(
      <Router history={history}  basename={process.env.PUBLIC_URL}>
          <ScrollToTop />
          <App />
      </Router>, rootElement);
}



