import React from 'react';
import Footer from '../Footer/Footer';
import MenuLayout from '../Layouts/MenuLayout/MenuLayout';

const MainLayout = ({ children }) => {
    return (
        <>
            <MenuLayout />
            {children}
            <Footer />
        </>
    );
};

export default MainLayout;